console.log('Script loaded');

function handleCredentialResponse(response) {
  console.log('Encoded JWT ID token: ' + response.credential);
  const receivedToken = response.credential;

  // Decode the JWT to extract user information
  const base64Url = receivedToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  const user = JSON.parse(jsonPayload);
  console.log('Decoded user information:', user);

  // Post user information to the API
  fetch('https://api.hoax.com/api/users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      googleId: user.sub,
      email: user.email,
      name: user.name,
      profilePicture: user.picture
    })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    console.log('User details saved:', data);
    if (data.message === 'User details saved successfully') {
      // Store the token in local storage
      console.log('Storing token:', receivedToken);
      localStorage.setItem('authToken', receivedToken);
      // Update the UI
      checkLoginStatus();
    } else {
      console.error('Failed to save user details:', data.message);
    }
  })
  .catch(error => {
    console.error('Error posting user details:', error);
  });
}

// Ensure DOM elements are available before adding event listeners
document.addEventListener('DOMContentLoaded', () => {
  const logoutLink = document.getElementById('logoutLink');
  const switchUserLink = document.getElementById('switchUserLink');
  const userInfo = document.getElementById('userInfo');

  if (logoutLink) {
    logoutLink.addEventListener('click', (e) => {
      e.preventDefault();
      console.log('Logout link clicked');
      // Remove the token from local storage
      localStorage.removeItem('authToken');
      // Update the UI
      userInfo.style.display = 'none';
      document.querySelector('.g_id_signin').style.display = 'block';
    });
  }

  if (switchUserLink) {
    switchUserLink.addEventListener('click', (e) => {
      e.preventDefault();
      console.log('Switch user link clicked');
      // Log out and show the Google Sign-In button
      localStorage.removeItem('authToken');
      userInfo.style.display = 'none';
      document.querySelector('.g_id_signin').style.display = 'block';
    });
  }

  if (userInfo) {
    userInfo.addEventListener('click', () => {
      const dropdownMenu = document.getElementById('dropdownMenu');
      dropdownMenu.style.display = dropdownMenu.style.display === 'block' ? 'none' : 'block';
    });
  }

  checkLoginStatus();
});

async function fetchPublicKeys() {
  try {
    const response = await fetch('https://www.googleapis.com/oauth2/v3/certs');
    const text = await response.text(); // Get the response as text
console.log('Response text:', text);
    console.log('Response text:', text); // Log the raw response
    const publicKeys = JSON.parse(text); // Parse the JSON
    console.log('Fetched public keys:', publicKeys);
  } catch (error) {
    console.error('Error fetching public keys:', error);
  }
}

// Call the function to fetch public keys after the DOM is fully loaded
document.addEventListener('DOMContentLoaded', () => {
  fetchPublicKeys();
});

function checkLoginStatus() {
  // Check if the user is logged in
  const authToken = localStorage.getItem('authToken');
  console.log('Auth token from local storage:', authToken);
  if (authToken) {
    fetch('https://api.hoax.com/api/verify-token', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Received data from verify token:', data);
      if (data.success) {
        // Display user information
        document.getElementById('profileImage').src = data.user.picture;
        document.getElementById('profileName').textContent = data.user.name;
        // Show the profile info and hide the Google Sign-In button
        document.getElementById('userInfo').style.display = 'flex';
        document.querySelector('.g_id_signin').style.display = 'none';
      } else {
        console.error('Token verification failed:', data.message);
      }
    })
    .catch(error => {
      console.error('Error verifying token:', error);
    });
  } else {
    console.log('No auth token found in local storage');
    // Show the Google Sign-In button
    document.querySelector('.g_id_signin').style.display = 'block';
    document.getElementById('userInfo').style.display = 'none';
    // Check if the Google Identity Services library is loaded
    if (window.google && google.accounts && google.accounts.id) {
      // Prompt the user to sign in
      google.accounts.id.prompt();
    } else {
      console.error('Google Identity Services library is not loaded');
    }
  }
}

// Check login status on page load
window.onload = checkLoginStatus;
